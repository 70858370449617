import logo from '../images/logo.png'
import topWork from '../images/top-work.png'
import * as XLSX from 'xlsx'
import '../App.css'
import NavBar from '../components/Navbar'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Toolbar,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import FluidContainer from '../components/FluidContainer'
import Footer from '../components/Footer'
import data from '../data.xlsx'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'

const ButtonLink = ({ active, children, ...props }) => {
  return (
    <Button
      sx={{
        color: active ? '#282828' : 'white',
        textTransform: 'none',
        whiteSpace: 'nowrap',
        width: '120px',
        m: 1.5,
        borderColor: 'white',
        background: active ? '#f9f2a0' : 'transparent',
        '&:hover': {
          color: '#282828',
          background: '#f3f0ca',
        },
      }}
      variant="outlined"
      size="large"
      {...props}
    >
      {children}
    </Button>
  )
}
const WorkPage = () => {
  const [loading, setLoading] = useState(true)
  const [allData, setAllData] = useState([])
  const [activeCat, setActiveCat] = useState('all')
  const { pathname, hash, key } = useLocation()
  let navigate = useNavigate()
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash !== '') {
      const type = hash.replace('#', '')
      setActiveCat(type)
    } else {
      setActiveCat('all')
    }
  }, [pathname, hash, key])

  useEffect(() => {
    const getData = async () => {
      const result = await axios({
        method: 'GET',
        responseType: 'arraybuffer',
        url: '/files/data.xlsx',
      }).catch((err) => console.log(err))
      var arraybuffer = result.data

      /* convert data to binary string */
      var data = new Uint8Array(arraybuffer)
      var arr = []
      for (var i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i])
      var bstr = arr.join('')
      const wb = XLSX.read(bstr, { type: 'binary' })

      // console.log('wb', wb)
      let totalCleanData = []
      const initType = ['2d', '3d', 'illust', 'others']
      const typeFolders = ['2D', '3D', 'Illus', 'Mixed Media', 'Motion Graphic']
      wb.SheetNames.forEach((eachSheet, sheetId) => {
        const wsname = eachSheet
        const ws = wb.Sheets[wsname]
        const rawData = XLSX.utils.sheet_to_json(ws, { header: 1 })
        const cleanData = rawData
          .filter((el, index) => {
            return el.length > 1 && index !== 0 && el?.[1]
          })
          .map((el) => {
            const tempEL = [...el]
            tempEL[0] = Number(el[0])
            let type = '2d'
            if (sheetId >= 3) type = 'others'
            else {
              type = initType[sheetId]
            }
            const elObj = {
              name: el[1],
              type: el[13],
              thumbnail: `https://tungshare.s3.ap-southeast-1.amazonaws.com/work/${typeFolders[sheetId]}/${el[1]
                .trim()
                .replaceAll(' ', '+')}/tb.jpg`,
              catType: type,
              dir: typeFolders[sheetId],
            }
            return elObj
          })
        totalCleanData = [...totalCleanData, ...cleanData]
      })
      setAllData(totalCleanData)
      setLoading(false)
    }
    getData()
  }, [])
  const navItems = [
    {
      name: 'Home',
      url: '/',
    },
    { name: 'Intro', url: '/#intro' },
    { name: 'Work', url: '/work' },
    { name: 'About Us', url: '/about' },
  ]
  // const catList = [{
  //   name: 'All',
  //   filter:
  // }]

  let usedAllData = allData.map((er, id) => {
    return { ...er, id }
  })
  if (activeCat !== 'all')
    usedAllData = usedAllData
      .filter((el) => {
        return el.catType === activeCat
      })
      .sort((a, b) => a.name - b.name)
  else {
    usedAllData = _.shuffle(usedAllData)
  }

  return (
    <Box sx={{ background: '#0c0745' }}>
      <NavBar navItems={navItems} defaultIndex={2} endIndex={0} />
      {/* <Box
        ref={section2Ref}
        component="main"
        sx={{
          backgroundColor: '#0c0745',
          height: '100vh',
          backgroundImage: `url(${topWork})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      /> */}
      <Box>
        <Box
          component="main"
          sx={{
            pt: 20,
            backgroundColor: '#0E1139',
            backgroundImage: `url(${topWork})`,
            backgroundSize: '100%',
            minHeight: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
          }}
        >
          <FluidContainer sx={{ justifyContent: 'center', display: 'flex' }}>
            <Box display="flex" sx={{ flexWrap: 'wrap', alignItems: 'flex-end' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <Typography
                  sx={{ ml: 3, fontFamily: 'Sriracha', color: '#6EF18D', fontSize: '180px', lineHeight: '180px' }}
                >
                  69
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Sriracha',
                    color: '#ffffff',
                    fontSize: '46px',
                    lineHeight: '60px',
                    mb: '40px',
                    ml: 2,
                  }}
                >
                  ซุ้ม
                </Typography>
              </Box>
              <Box sx={{ mb: 3, mx: 4 }}>
                <Typography sx={{ ml: 2 }}>อยากไปซุ้มไหนดี</Typography>
                <Box>
                  <ButtonLink
                    active={activeCat === 'all'}
                    onClick={() => {
                      navigate('/work')
                    }}
                  >
                    All
                  </ButtonLink>
                  <ButtonLink
                    active={activeCat === 'illust'}
                    onClick={() => {
                      navigate('/work#illust')
                    }}
                  >
                    Illustrations
                  </ButtonLink>
                  <ButtonLink
                    active={activeCat === '2d'}
                    onClick={() => {
                      navigate('/work#2d')
                    }}
                  >
                    2D Animations
                  </ButtonLink>
                  <ButtonLink
                    active={activeCat === '3d'}
                    onClick={() => {
                      navigate('/work#3d')
                    }}
                  >
                    3D Animations
                  </ButtonLink>
                  <ButtonLink
                    active={activeCat === 'others'}
                    onClick={() => {
                      navigate('/work#others')
                    }}
                  >
                    Others
                  </ButtonLink>
                </Box>
              </Box>
            </Box>
          </FluidContainer>
          <FluidContainer>
            <Box
              sx={{
                width: '100%',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              {loading ? (
                <CircularProgress sx={{ mt: 20, color: '#ffffff' }} />
              ) : (
                <>
                  {usedAllData.map((content, elIndex) => {
                    return (
                      <Box
                        key={content.id}
                        sx={{
                          width: 250,
                          height: 350,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          m: 2,
                        }}
                      >
                        <Card
                          onClick={() => {
                            navigate(`/work/${content.dir}/${content.id}`)
                          }}
                          key={content.name}
                          sx={{
                            border: '6px solid #E1A0F9',
                            borderRadius: '20px',
                            position: 'absolute',
                            zIndex: 1,
                            width: 250,
                            transition: 'width .3s ease-out, height .3s ease-out',
                            height: 350,
                            maxWidth: '100%',
                            background: '#0c0745',
                            '&:hover': {
                              border: '6px solid #F9F2A0',
                              width: 350,
                              height: 450,
                              zIndex: 1000,
                              maxWidth: '100%',
                              '& #typeWork': {
                                opacity: 1,
                              },
                              '& #cover': {
                                height: 350,
                                opacity: 1,
                              },
                            },
                          }}
                        >
                          <CardActionArea>
                            <CardMedia
                              sx={{
                                p: 2,
                                objectFit: 'cover',
                                transition: 'width .3s ease-out, height .3s ease-out',
                                borderRadius: '20px',
                              }}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null // prevents loopingฃ
                                currentTarget.src = `${content.thumbnail.substr(0, content.thumbnail.length - 4)}.png`
                                // currentTarget.src = '/logo.png'
                              }}
                              id="cover"
                              component="img"
                              height="250"
                              image={content.thumbnail}
                              alt="green iguana"
                            />
                            <CardContent sx={{ paddingTop: 0 }}>
                              <Typography gutterBottom sx={{ color: 'white' }} variant="h5" component="div">
                                {content.name}
                              </Typography>
                              <Typography
                                id="typeWork"
                                sx={{ transition: 'opacity .3s ease-out', color: 'white', opacity: 0 }}
                                variant="body1"
                                color="text.secondary"
                              >
                                Type: {content.type}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Box>
                    )
                  })}
                </>
              )}
            </Box>
          </FluidContainer>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default WorkPage
