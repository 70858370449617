import logo from '../images/logo.png'
import treeBackground from '../images/homepageTree.png'
import * as XLSX from 'xlsx'
import topWork from '../images/top-work.png'
import '../App.css'
import NavBar from '../components/Navbar'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import FluidContainer from '../components/FluidContainer'
import Footer from '../components/Footer'
import data from '../data.xlsx'
import axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { imageExist } from '../methods/imageExist'
import { checkImage } from '../methods/checkImage'
import PdfView from '../components/PdfView'

const YoutubeEmbed = ({ link }) => {
  const totalSplit = link.split('/')
  const embId = totalSplit[totalSplit.length - 1]
  return (
    <Box
      sx={{
        overflow: 'hidden',
        height: 460,
        width: 853,
        backgroud: '#484848',
        maxWidth: '100%',
        '& iframe': {
          width: '853px',
          maxWidth: '100%',
        },
      }}
    >
      <iframe
        width="853"
        height="460"
        src={`https://www.youtube.com/embed/${embId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        rel="0"
        controls="0"
        title="Embedded youtube"
      />
    </Box>
  )
}

const ImageCard = ({ src, typeUsed, setTypeUsed, currentBuffer, setIsErrorImage, setActiveImage }) => {
  let letCheck = 1
  return (
    <Box
      onError={({ currentTarget }) => {
        currentTarget.style.opacity = 0
        checkImage(currentTarget, src)
      }}
      onLoad={({ currentTarget }) => {
        currentTarget.style.display = 'block'
        currentTarget.style.opacity = 1
        setIsErrorImage(false)
      }}
      sx={{
        width: 300,
        minHeight: 150,
        m: 2,
        opacity: 1,
        '&:hover': {
          cursor: 'pointer',
          opacity: 0.8,
        },
      }}
      onClick={(currentTarget) => {
        setActiveImage(src)
      }}
      component="img"
      src={src}
    />
  )
}

const DetailPage = () => {
  const section2Ref = React.useRef()
  const [loading, setLoading] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  let { type, workId } = useParams()
  const [allData, setAllData] = useState([])
  let navigate = useNavigate()
  const [activeMember, setActiveMember] = useState(0)
  const [activeImage, setActiveImage] = useState(null)
  const [isErrorImage, setIsErrorImage] = useState(false)
  const [currentBuffer, setCurrentBuffer] = useState(0)
  const [typeUsed, setTypeUsed] = useState('jpg')

  useEffect(() => {
    const getData = async () => {
      const result = await axios({
        method: 'GET',
        responseType: 'arraybuffer',
        url: '/files/data.xlsx',
      }).catch((err) => console.log(err))
      var arraybuffer = result.data

      /* convert data to binary string */
      var data = new Uint8Array(arraybuffer)
      var arr = []
      for (var i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i])
      var bstr = arr.join('')
      const wb = XLSX.read(bstr, { type: 'binary' })

      // console.log('wb', wb)
      let totalCleanData = []
      const initType = ['2d', '3d', 'illust', 'others']
      const typeFolders = ['2D', '3D', 'Illus', 'Mixed Media', 'Motion Graphic']
      wb.SheetNames.forEach((eachSheet, sheetId) => {
        const wsname = eachSheet
        const ws = wb.Sheets[wsname]
        const rawData = XLSX.utils.sheet_to_json(ws, { header: 1 })
        const newArray = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        var img = document.createElement('img')
        const cleanData = rawData
          .filter((el, index) => {
            return el.length > 1 && index !== 0 && el?.[1]
          })
          .map((el) => {
            const tempEL = [...el]
            tempEL[0] = Number(el[0])
            let type = '2d'
            const name = el[1]
            if (sheetId >= 3) type = 'others'
            else {
              type = initType[sheetId]
            }
            const elObj = {
              name: name,
              youtube: el[2],
              genre: el[3],
              story: el[4],
              type: el[13],
              totalImages: el[15],
              pdf: `https://tungshare.s3.ap-southeast-1.amazonaws.com/work/${typeFolders[sheetId]}/${name
                .trim()
                .replaceAll(' ', '+')}/1.pdf`,
              images: newArray.map(
                (el, ind) =>
                  `https://tungshare.s3.ap-southeast-1.amazonaws.com/work/${typeFolders[sheetId]}/${name
                    .trim()
                    .replaceAll(' ', '+')}/c/${ind + 1}.jpg`,
              ),
              members: [el[5], el[7], el[9], el[11]]
                .filter((el) => {
                  return el && el.trim() !== '-'
                })
                .map((el) => {
                  return {
                    id: el.split(' ')[0],
                    name: `${el.split(' ')[1] ?? ''} ${el.split(' ')[2] ?? ''}`,
                    image: `https://tungshare.s3.ap-southeast-1.amazonaws.com/work/${
                      typeFolders[sheetId]
                    }/${name.trim()}/${el.split(' ')[0]}.jpg`,
                  }
                }),
              contacts: [el[6], el[8], el[10], el[12]].filter((el) => {
                return el && el.trim() !== '-'
              }),
              thumbnail: `https://tungshare.s3.ap-southeast-1.amazonaws.com/work/${typeFolders[sheetId]}/${el[1]}/tb.jpg`,
              catType: type,
            }
            return elObj
          })
        totalCleanData = [...totalCleanData, ...cleanData]
      })
      setAllData(totalCleanData)
      setLoading(false)
    }
    getData()
  }, [])

  const navItems = [
    {
      name: 'Home',
      url: '/',
    },
    { name: 'Intro', url: '/#intro' },
    { name: 'Work', url: '/work' },
    { name: 'About Us', url: '/about' },
  ]
  // const catList = [{
  //   name: 'All',
  //   filter:
  // }]

  if (loading) return <></>

  const activeProject = allData[workId]

  let usedActiveImage = activeImage || activeProject.images[0]
  let count = 0

  return (
    <Box sx={{ background: '#0c0745' }}>
      <NavBar navItems={navItems} defaultIndex={2} endIndex={0} />
      {/* <Box
        ref={section2Ref}
        component="main"
        sx={{
          backgroundColor: '#0c0745',
          height: '20vh',
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      /> */}
      <Box
        sx={{
          pt: 20,
          backgroundColor: '#0E1139',
          backgroundImage: `url(${topWork})`,
          backgroundSize: '100%',
          minHeight: '100vh',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Box component="img" sx={{ zIndex: 100, width: '150px' }} src="/logo.png" />
        <FluidContainer
          sx={{
            background: '#420F6E',
            mt: -5,
            borderRadius: '20px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            justifyContent: 'center',
          }}
        >
          <Box sx={{ mt: 10, display: 'flex', justifyContent: 'center' }}>
            <YoutubeEmbed link={activeProject.youtube} />
          </Box>
          <Box
            sx={{
              width: '100%',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              flexWrap: 'wrap',
              mb: 10,
              p: 2,
            }}
          >
            <Typography sx={{ fontFamily: 'Sriracha', fontSize: '32px', mt: 10 }} variant="h5">
              {activeProject.name}
            </Typography>
            <Typography sx={{ fontFamily: 'RSU', fontSize: '26px', mt: 2 }} variant="h5">
              {activeProject.genre}
            </Typography>
            <Typography
              sx={{ width: 600, maxWidth: '100%', fontFamily: 'RSU', fontSize: '18px', mt: 2, mb: 3 }}
              variant="body1"
            >
              {activeProject.story}
            </Typography>
            {type === 'Illus' && <PdfView src={activeProject.pdf} />}
            {workId === '38' && <PdfView src={`${activeProject.pdf.substr(0, activeProject.pdf.length - 5)}2.pdf`} />}
            {workId === '38' && <PdfView src={`${activeProject.pdf.substr(0, activeProject.pdf.length - 5)}3.pdf`} />}
            <Box
              component="img"
              sx={{ borderRadius: '20px', width: 853, height: 460, maxWidth: '100%', objectFit: 'cover' }}
              src={usedActiveImage}
              onError={({ currentTarget }) => {
                checkImage(currentTarget, usedActiveImage)
              }}
            />
          </Box>
        </FluidContainer>
        <Box
          sx={{
            py: 5,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#472F78',
          }}
        >
          <Box
            display="flex"
            sx={{
              flexDirection: {
                md: 'row',
                xs: 'column',
              },
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {currentBuffer > 0 && (
              <IconButton
                sx={{
                  width: 50,
                  border: '2px solid #fff',
                  '&:hover': {
                    background: 'rgba(0,0,0,0.5)',
                  },
                }}
                aria-label="left"
                size="medium"
                onClick={() => {
                  setCurrentBuffer(currentBuffer - 3)
                }}
              >
                <KeyboardArrowLeft sx={{ fontSize: '30px', color: 'white' }} fontSize="inherit" />
              </IconButton>
            )}
            <ImageCard
              setIsErrorImage={setIsErrorImage}
              typeUsed={typeUsed}
              setTypeUsed={setTypeUsed}
              setActiveImage={setActiveImage}
              currentBuffer={currentBuffer}
              src={`${activeProject.images?.[currentBuffer + 0].substr(
                0,
                activeProject.images?.[currentBuffer + 0].length - 4,
              )}.${typeUsed}`}
            />
            <ImageCard
              setIsErrorImage={setIsErrorImage}
              typeUsed={typeUsed}
              setTypeUsed={setTypeUsed}
              setActiveImage={setActiveImage}
              currentBuffer={currentBuffer}
              src={`${activeProject.images?.[currentBuffer + 1].substr(
                0,
                activeProject.images?.[currentBuffer + 1].length - 4,
              )}.${typeUsed}`}
            />
            <ImageCard
              setIsErrorImage={setIsErrorImage}
              typeUsed={typeUsed}
              setTypeUsed={setTypeUsed}
              setActiveImage={setActiveImage}
              currentBuffer={currentBuffer}
              src={`${activeProject.images?.[currentBuffer + 2].substr(
                0,
                activeProject.images?.[currentBuffer + 2].length - 4,
              )}.${typeUsed}`}
            />
            {activeProject.totalImages > (currentBuffer + 1) * 3 && (
              <IconButton
                sx={{
                  border: '2px solid #fff',
                  '&:hover': {
                    background: 'rgba(0,0,0,0.5)',
                  },
                }}
                aria-label="right"
                size="medium"
                onClick={() => {
                  setCurrentBuffer(currentBuffer + 3)
                }}
              >
                <KeyboardArrowRight sx={{ fontSize: '30px', color: 'white' }} fontSize="inherit" />
              </IconButton>
            )}
          </Box>
        </Box>
        <FluidContainer
          sx={{
            background: '#420F6E',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
            pb: 10,
          }}
        >
          {/* <Box
            sx={{
              maxWidth: '100%',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              flexWrap: 'wrap',
              mb: 10,
            }}
          > */}
          <Typography sx={{ fontFamily: 'Sriracha', fontSize: '32px', mt: 10 }} variant="h5">
            ทีมงานคนโก้
          </Typography>
          <Box
            mt={5}
            sx={{
              maxWidth: '100%',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
            }}
            display="flex"
            px={3}
            justifyContent="flex-start"
          >
            {activeProject.members.map((member, ind) => {
              return (
                <Box
                  key={member.id}
                  sx={{
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                    display: 'flex',
                    mx: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: activeMember === ind ? '#282828' : 'white',
                    background: activeMember === ind ? 'white' : '#080640',
                    width: '150px',
                    height: '50px',
                    '&:hover': {
                      opacity: 0.9,
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => setActiveMember(ind)}
                >
                  {member.id}
                </Box>
              )
            })}
          </Box>
          <Box
            sx={{
              width: 850,
              maxWidth: '100%',
              background: 'white',
              p: 5,
              borderRadius: '15px',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: {
                xs: 'center',
                md: 'flex-start',
              },
            }}
          >
            <Box
              component="img"
              sx={{ borderRadius: '5px', width: 280, height: 280, objectFit: 'cover' }}
              src={activeProject.members[activeMember].image}
              onError={({ currentTarget }) => {
                checkImage(currentTarget, activeProject.members[activeMember].image)
              }}
            />
            <Box sx={{ p: 3 }}>
              <Typography sx={{ color: '#000000', fontSize: '28px' }}>
                {activeProject.members[activeMember].name}
              </Typography>
              <Typography sx={{ color: '#000000', fontSize: '20px' }}>
                {activeProject.members[activeMember].id}
              </Typography>
              <Typography sx={{ pt: 2, color: '#000000', fontSize: '18px' }}>
                {activeProject.contacts.length > 0 &&
                  (activeProject.contacts[activeMember] ?? '').split('\n').map((el) => (
                    <>
                      {el}
                      <br />
                    </>
                  ))}
              </Typography>
            </Box>
            {/* </Box> */}
          </Box>
        </FluidContainer>
      </Box>
      <Footer />
    </Box>
  )
}

export default DetailPage
