import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import FluidContainer from './FluidContainer'
import { useNavigate } from 'react-router-dom'

const drawerWidth = 240

const nearestIndex = (currentPosition, sectionPositionArray, startIndex, endIndex) => {
  if (startIndex === endIndex) return startIndex
  else if (startIndex === endIndex - 1) {
    if (
      Math.abs(sectionPositionArray[startIndex].headerRef.current.offsetTop - currentPosition) <
      Math.abs(sectionPositionArray[endIndex].headerRef.current.offsetTop - currentPosition)
    )
      return startIndex
    else return endIndex
  } else {
    var nextNearest = ~~((startIndex + endIndex) / 2)
    var a = Math.abs(sectionPositionArray[nextNearest].headerRef.current.offsetTop - currentPosition)
    var b = Math.abs(sectionPositionArray[nextNearest + 1].headerRef.current.offsetTop - currentPosition)
    if (a < b) {
      return nearestIndex(currentPosition, sectionPositionArray, startIndex, nextNearest)
    } else {
      return nearestIndex(currentPosition, sectionPositionArray, nextNearest, endIndex)
    }
  }
}

export default function NavBar({ navItems, defaultIndex, endIndex = 1 }) {
  const container = undefined
  let navigate = useNavigate()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)
  React.useEffect(() => {
    const handleScroll = (e) => {
      var index = nearestIndex(window.scrollY, navItems, 0, endIndex)
      setActiveIndex(index)
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{textAlign: 'center' }}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              onClick={() => {
                if (item.handleClick) item.handleClick()
                else if (item.url) navigate(item.url)
              }}
              sx={{ textAlign: 'center' }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        sx={{
          borderBottomWidth: 0,
          boxShadow: 'none',
          maxWidth: '100%',
          background:
            'linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(3,3,28,0.7483368347338936) 44%, rgba(0,212,255,0) 100%)',
        }}
        component="nav"
      >
        <FluidContainer>
          <Toolbar sx={{ p: 0 }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ ml: 2, mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                justifyContent: 'space-between',
                width: '100%',
                maxWidth: '100%',
                padding: '60px 20px 10px 20px',
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {navItems.map((item, index) => (
                <Button
                  variant="outlined"
                  key={item.name}
                  onClick={() => {
                    if (item.handleClick) item.handleClick()
                    else if (item.url) navigate(item.url)
                  }}
                  sx={{
                    width: '200px',
                    color: defaultIndex
                      ? defaultIndex === index
                        ? '#282828'
                        : '#fff'
                      : activeIndex === index
                      ? '#282828'
                      : '#fff',
                    height: '50px',
                    fontSize: '20px',
                    border: '1px solid #fff',
                    borderRadius: '50px',
                    background: defaultIndex
                      ? defaultIndex === index
                        ? '#F9ED32'
                        : '#00000050'
                      : activeIndex === index
                      ? '#F9ED32'
                      : '#00000050',
                    '&:hover': {
                      color: '#282828',
                      background: '#fffab8',
                    },
                  }}
                >
                  {item.name}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </FluidContainer>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              background: '#0e1139',
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  )
}
