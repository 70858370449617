import logo from '../images/logo.png'
import footerImage from '../images/footerImage.png'
import logoRSU from '../images/logo-rsu.png'
import logoDigitalArt from '../images/logo-digitalart.png'
import logoComArt from '../images/logo-comart.png'
import '../App.css'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { People, Phone } from '@mui/icons-material'

const Footer = () => {
  return (
    <>
      <Box
        component="div"
        sx={{
          paddingBottom: '25vh',
          maxWidth: '100%',
          backgroundColor: '#0E1139',
          backgroundImage: `url(${footerImage})`,
          backgroundSize: 'contain',
          height: '130vh',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          pt: {
            xs: 50,
            md: 10,
          },
        }}
      >
        <Box component="img" alt="intro" sx={{ m: 3, width: '200px', maxWidth: '100%' }} src={logo} />
        <Typography sx={{ m: 3, fontSize: '16px', color: 'white', minWidth: '300px' }}>
          Digital Art, Computer Art, 2022
          <br /> คณะดิจิทัลอาร์ต มหาลัยรังสิต
          <br /> <Phone sx={{ mb: -0.8, width: '18px', mr: 1 }} /> (662) 997-2200
          <br /> <People sx={{ mb: -0.8, width: '18px', mr: 1 }} /> เจ้าหน้าที่สาขาวิชาคอมพิวเตอร์อาร์ต 5204
        </Typography>
        <Box component="img" alt="intro" sx={{ m: 3, width: '80px', maxWidth: '100%' }} src={logoDigitalArt} />
        <Box component="img" alt="intro" sx={{ m: 3, width: '100px', maxWidth: '100%' }} src={logoRSU} />
        <Box component="img" alt="intro" sx={{ m: 3, width: '150px', maxWidth: '100%' }} src={logoComArt} />
      </Box>
    </>
  )
}

export default Footer
