import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import HTMLFlipBook from 'react-pageflip'
import { Box, CircularProgress, IconButton } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight, OpenInBrowser } from '@mui/icons-material'
import { Navigate } from 'react-router-dom'

const MyDocument = Document
const ReactPdfPage = Page

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const PdfView = ({ src }) => {
  const [numPages, setNumPages] = useState(null)
  const refBox = React.useRef()
  const boxRef = React.useRef()
  const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const newArray = new Array(numPages).fill(0)

  const width = refBox.current?.offsetWidth < 600 ? refBox.current?.offsetWidth : 800

  return (
    <Box
      ref={refBox}
      sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', maxWidth: '100%', mb: 10 }}
    >
      {/* <MyDocument
        file={{
          url: src,
        }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <MyPage pageNumber={pageNumber} />
      </MyDocument> */}
      <MyDocument
        loading={
          <Box sx={{ width, height: width, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        }
        file={src}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {/* <HTMLFlipBook width={width} height={height}> */}
        {/* {newArray.map((numPage, ind) => ( */}
        <ReactPdfPage
          loading={
            <Box sx={{ width, height: width, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          }
          pageNumber={pageNumber}
          width={width}
        />
        {/* ))} */}
        {/* </HTMLFlipBook> */}
      </MyDocument>
      <Box sx={{ p: 2, display: 'flex', mt: -10, width: width, justifyContent: 'space-between' }}>
        <IconButton
          sx={{
            border: '2px solid #fff',
            opacity: pageNumber > 1 ? 1 : 0,
            background: 'rgba(0,0,0,0.5)',
            '&:hover': {
              background: 'rgba(0,0,0,0.9)',
            },
          }}
          aria-label="right"
          size="medium"
          onClick={() => {
            if (pageNumber > 1) setPageNumber(pageNumber - 1)
          }}
        >
          <KeyboardArrowLeft sx={{ fontSize: '30px', color: 'white' }} fontSize="inherit" />
        </IconButton>

        <IconButton
          sx={{
            border: '2px solid #fff',
            background: 'rgba(0,0,0,0.5)',
            '&:hover': {
              background: 'rgba(0,0,0,0.9)',
            },
          }}
          aria-label="right"
          size="medium"
          onClick={() => {
            window.open(src, '_blank')
          }}
        >
          <OpenInBrowser sx={{ fontSize: '30px', color: 'white' }} fontSize="inherit" />
        </IconButton>

        {pageNumber < numPages && (
          <IconButton
            sx={{
              border: '2px solid #fff',
              background: 'rgba(0,0,0,0.5)',
              '&:hover': {
                background: 'rgba(0,0,0,0.5)',
              },
            }}
            aria-label="right"
            size="medium"
            onClick={() => {
              setPageNumber(pageNumber + 1)
            }}
          >
            <KeyboardArrowRight sx={{ fontSize: '30px', color: 'white' }} fontSize="inherit" />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default PdfView
