import './App.css'
import { Route, Routes, Navigate } from 'react-router-dom'
import Homepage from './pages'
import Work from './pages/work'
import WorkDetail from './pages/workDetails'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import rsuttf from './font/rsufont/RSU_Regular.ttf'
import srirachattf from './font/sriracha/Sriracha-Regular.ttf'
import AboutPage from './pages/about'
const theme = createTheme({
  typography: {
    fontFamily: 'RSU, Arial',
    body1: {
      fontSize: '20px',
      color: 'white',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'RSU';
          font-style: normal;
          font-weight: 400;
          src: local('RSU'), local('RSU-Regular'), url(${rsuttf}) format('truetype');
        }
        @font-face {
          font-family: 'Sriracha';
          font-style: normal;
          font-weight: 400;
          src: local('Sriracha'), local('Sriracha-Regular'), url(${srirachattf}) format('truetype');
        }
      `,
    },
  },
})
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/work" element={<Work />} />
        <Route path="/work/:type/:workId" element={<WorkDetail />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </ThemeProvider>
  )
}

export default App
