import logo from '../images/logo.png'
import treeBackground from '../images/homepageTree.png'
import theaterBackground from '../images/theater.png'
import wordingImage from '../images/wording.png'
import '../App.css'
import NavBar from '../components/Navbar'
import { Box, Toolbar, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import { KeyboardArrowDown, People, Phone } from '@mui/icons-material'
import FluidContainer from '../components/FluidContainer'
import { useLocation } from 'react-router-dom'
import Footer from '../components/Footer'

const Homepage = () => {
  const section1Ref = React.useRef()
  const section2Ref = React.useRef()
  const { pathname, hash, key } = useLocation()
  const handleClickMain = () => {
    section1Ref.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const handleClickIntro = () => {
    const element = document.getElementById('intro')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0)
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 0)
    }
  }, [pathname, hash, key])
  const navItems = [
    {
      name: 'Home',
      headerRef: section1Ref,
      handleClick: handleClickMain,
    },
    { name: 'Intro', headerRef: section2Ref, handleClick: handleClickIntro },
    { name: 'Work', url: '/work' },
    { name: 'About Us', url: '/about' },
  ]
  return (
    <Box sx={{ background: '#0c0745' }}>
      <Box ref={section1Ref} sx={{ paddingBottom: 0 }} className="App-header">
        <NavBar navItems={navItems} />
        <Toolbar />
        <img src={logo} className="App-logo" alt="logo" />
        <IconButton
          sx={{
            border: '4px solid #fff',
            marginTop: '30vmin',
            background: 'rgba(0,0,0,0.3)',
            '&:hover': {
              background: 'rgba(0,0,0,0.5)',
            },
          }}
          aria-label="delete"
          size="large"
          onClick={handleClickIntro}
        >
          <KeyboardArrowDown sx={{ fontSize: '50px', color: 'white' }} fontSize="inherit" />
        </IconButton>
      </Box>
      <Box
        sx={{
          mt: -15,
          background: ' linear-gradient(0deg, rgba(12,7,69,1) 0%, rgba(12,7,69,0.75) 44%, rgba(12,7,69,0) 100%)',
          height: '120px',
          width: '100%',
          bottom: 0,
        }}
      />
      <Box>
        <Box
          component="main"
          sx={{
            // paddingBottom: '25vh',
            pt: {
              xs: 0,
              md: '30vh',
            },
            backgroundColor: '#0c0745',
            backgroundImage: `url(${treeBackground})`,
            backgroundSize: 'cover',
            height: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
          }}
        >
          <FluidContainer sx={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>
            <Box
              id="intro"
              ref={section2Ref}
              sx={{
                maxWidth: '100%',
                width: '800px',
                pt: 20,
                backgroundImage: `url(${theaterBackground})`,
                backgroundSize: 'contain',
                height: '60vh',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
                backgroud: '#484848',
                '& iframe': {
                  width: '100%',
                  height: '40vh',
                  maxWidth: '100%',
                },
              }}
            >
              <iframe
                width="600"
                height="40vh"
                src={`https://www.youtube.com/embed/FSZJD_fKa68`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                rel="0"
                controls="0"
                title="Embedded youtube"
              />
            </Box>
            <Box
              sx={{
                p: 2,
                display: 'flex',
                width: '400px',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box component="img" alt="intro" sx={{ mt: 2, width: '400px', maxWidth: '100%' }} src={wordingImage} />
              <Typography sx={{ color: 'white', mt: 3 }}>
                นิทรรศการตุ้งแช่ธีสิสเอ็กซิบิชั่น เกิดมาจากแนวคิดที่ว่า งานของนักศึกษาแต่ละคน
                มีเรื่องราวที่แตกต่างราวกับสีสันและรสชาติที่หลากหลาย ดั่งแสงสีเสียงภายในงานวัด
                พร้อมมอบความสนุกสนานให้แก่ทุกท่านที่ได้รับชม
              </Typography>
              <Typography sx={{ color: 'white', pt: 3 }}>
                "ตุ้งแช่" จึงเกิดขึ้นมา
                เพื่อเป็นการเฉลิมฉลองเทศกาลความรื่นเริงที่พวกเรานักศึกษาทุกคนได้มาส่งเสียงของตนเองร่วมกัน ณ ตรงนี้
              </Typography>
            </Box>
          </FluidContainer>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#0c0745',
          height: '60vh',
          display: {
            xs: 'flex',
            md: 'none',
          },
        }}
      />
      <Footer />
    </Box>
  )
}

export default Homepage
