import logo from '../images/logo.png'
import topAbout from '../images/top-about.png'
import * as XLSX from 'xlsx'
import '../App.css'
import NavBar from '../components/Navbar'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Toolbar,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import FluidContainer from '../components/FluidContainer'
import Footer from '../components/Footer'
import data from '../data.xlsx'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { checkImage } from '../methods/checkImage'
// 'อ_นัฐวุฒิ สีมันตร',
const teacherList = [
  'ผศ_ชูชัย อัศวอารีกุล',
  'ผศ_ธรรมศักดิ์ เอื้อรักสกุล',
  'รศ_ปกรณ์ พรหมวิทักษ์',
  'อ_Samuel Forkner',
  'อ_ดนุช จารุทรัพย์สดใส',
  'อ_ปฐม สุปรียาพร',
  'อ_พนัส โภคทวี',
  'อ_ภัทร นิมมล',
  'อ_สาธิต เชียงทอง',
  'อ_สุทธิชาติ ศราภัยวานิช',
  'อ_สุทัศน์ ปาละมะ',
  'อ_หทัยชนก เชียงทอง',
]

const ShowCard = ({ content, role, src }) => {
  return (
    <Box
      sx={{
        width: 250,
        height: 350,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        m: 2,
      }}
    >
      <Card
        key={content}
        sx={{
          borderRadius: '20px',
          position: 'absolute',
          zIndex: 1,
          width: 250,
          transition: 'width .3s ease-out, height .3s ease-out',
          height: 350,
          maxWidth: '100%',
          background: '#0E1139cc',
        }}
      >
        <CardActionArea>
          <CardMedia
            sx={{
              p: 2,
              objectFit: 'cover',
              transition: 'width .3s ease-out, height .3s ease-out',
              borderRadius: '20px',
            }}
            id="cover"
            component="img"
            height="250"
            image={src || `teacherImage/${content}.jpg`}
            alt={content}
          />
          <CardContent sx={{ paddingTop: 0, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            <Typography gutterBottom sx={{ color: '#FF99E2', fontSize: '24px' }} variant="body1" component="div">
              {content.replace('_', '.')}
            </Typography>
            <Typography id="typeWork" sx={{ color: 'white', fontSize: '16px' }} variant="body1" color="text.secondary">
              {role || 'อาจารย์'}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  )
}
const AboutPage = () => {
  const section2Ref = React.useRef()
  const [loading, setLoading] = useState(true)
  const [allData, setAllData] = useState([])
  const [activeCat, setActiveCat] = useState('all')
  const { pathname, hash, key } = useLocation()
  let navigate = useNavigate()
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash !== '') {
      const type = hash.replace('#', '')
      setActiveCat(type)
    } else {
      setActiveCat('all')
    }
  }, [pathname, hash, key])

  useEffect(() => {
    const getData = async () => {
      const result = await axios({
        method: 'GET',
        responseType: 'arraybuffer',
        url: '/files/data.xlsx',
      }).catch((err) => console.log(err))
      var arraybuffer = result.data

      /* convert data to binary string */
      var data = new Uint8Array(arraybuffer)
      var arr = []
      for (var i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i])
      var bstr = arr.join('')
      const wb = XLSX.read(bstr, { type: 'binary' })

      // console.log('wb', wb)
      let totalCleanData = []
      const initType = ['2d', '3d', 'illust', 'others']
      const typeFolders = ['2D', '3D', 'Illus', 'Mixed Media', 'Motion Graphic']
      wb.SheetNames.forEach((eachSheet, sheetId) => {
        const wsname = eachSheet
        const ws = wb.Sheets[wsname]
        const rawData = XLSX.utils.sheet_to_json(ws, { header: 1 })
        const cleanData = rawData
          .filter((el, index) => {
            return el.length > 1 && index !== 0 && el?.[1]
          })
          .map((el) => {
            const tempEL = [...el]
            const name = el[1]
            tempEL[0] = Number(el[0])
            const elObj = {
              members: [el[5], el[7], el[9], el[11]]
                .filter((el) => {
                  return el && el.trim() !== '-'
                })
                .map((el) => {
                  totalCleanData.push({
                    id: el.split(' ')[0],
                    name: `${el.split(' ')[1] ?? ''} ${el.split(' ')[2] ?? ''}`,
                    image: `https://tungshare.s3.ap-southeast-1.amazonaws.com/work/${
                      typeFolders[sheetId]
                    }/${name.trim()}/${el.split(' ')[0]}.jpg`,
                  })
                  return {
                    id: el.split(' ')[0],
                    name: `${el.split(' ')[1] ?? ''} ${el.split(' ')[2] ?? ''}`,
                    image: `https://tungshare.s3.ap-southeast-1.amazonaws.com/work/${typeFolders[sheetId]}/${name}/${
                      el.split(' ')[0]
                    }.jpg`,
                  }
                }),
            }
            return elObj
          })
        // totalCleanData = [...totalCleanData, ...cleanData]
      })
      setAllData(totalCleanData)
      setLoading(false)
    }
    getData()
  }, [])
  const navItems = [
    {
      name: 'Home',
      url: '/',
    },
    { name: 'Intro', url: '/#intro' },
    { name: 'Work', url: '/work' },
    { name: 'About Us', url: '/about' },
  ]
  // const catList = [{
  //   name: 'All',
  //   filter:
  // }]

  allData.sort((a, b) => a.id - b.id)
  // let usedAllData = allData.map((er, id) => {
  //   return { ...er, id }
  // })
  // if (activeCat !== 'all')
  //   usedAllData = usedAllData.filter((el) => {
  //     return el.catType === activeCat
  //   })
  return (
    <Box sx={{ background: '#0c0745' }}>
      <NavBar navItems={navItems} defaultIndex={3} endIndex={0} />
      {/* <Box
        ref={section2Ref}
        component="main"
        sx={{
          backgroundColor: '#0c0745',
          height: '100vh',
          backgroundImage: `url(${topAbout})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      /> */}
      <Box>
        <Box
          ref={section2Ref}
          component="main"
          sx={{
            pt: 20,
            backgroundColor: '#0E1139',
            backgroundImage: `url(${topAbout})`,
            backgroundSize: '100%',
            minHeight: '100vh',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top',
          }}
        >
          <FluidContainer sx={{ justifyContent: 'center', display: 'flex' }}>
            <Box display="flex" sx={{ flexWrap: 'wrap', alignItems: 'flex-end' }}>
              <Box component="img" sx={{ zIndex: 100, width: '250px' }} src="/logo.png" />
            </Box>
          </FluidContainer>
          <FluidContainer
            sx={{
              mt: 10,
              mb: 5,
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ mb: 5, fontFamily: 'Sriracha', color: '#FF99E2', fontSize: '42px' }}>
              คณะบันเทิงศิลป์ดิจิทัล
            </Typography>
            <ShowCard content={'อ_นัฐวุฒิ สีมันตร'} role="คณบดี" />
          </FluidContainer>
          <FluidContainer>
            <Box
              sx={{
                width: '100%',
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              {loading ? (
                <CircularProgress sx={{ mt: 20, color: '#ffffff' }} />
              ) : (
                <>
                  {teacherList.map((content, elIndex) => {
                    return <ShowCard key={content} content={content} />
                  })}
                  <Box sx={{ mt: 10, mb: 15, width: '90%', height: 2, borderTop: '1px solid #FF99E2' }} />
                  {allData.map((content, elIndex) => {
                    return <ShowCard key={content.id} content={content.name} role={content.id} src={content.image} />
                  })}
                </>
              )}
            </Box>
          </FluidContainer>
          <FluidContainer sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
          <Box sx={{ mt: 10, mb: 15, width: '90%', height: 2, borderTop: '1px solid #FF99E2' }} />
            <Typography sx={{ fontFamily: 'Sriracha', color: '#ffffff', fontSize: '24px' }}>
              SPECIAL THANKS
            </Typography>
            <Typography sx={{ mt: 5, fontFamily: 'RSU', color: '#ffffff', fontSize: '22px' }}>
              Graphic Design : Indeedly
              <br />
              Photographer : White Diamonds Studio
              <br />
              Web Developer : Plearn.io
            </Typography>
          </FluidContainer>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default AboutPage
